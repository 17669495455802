@charset "UTF-8";
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-slider__img img {
  display: block;
  width: 100%;
  line-height: 0;
}

/*
ユーティリティ系おまとめファイル
*/
#top .p-slider {
  padding: 0;
  line-height: 0;
}
#top .p-slider__img img {
  height: 850px;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
@media screen and (max-width: 768px) {
  #top .p-slider__img img {
    height: 800px;
  }
}
@media screen and (max-width: 568px) {
  #top .p-slider__img img {
    height: 100svh;
  }
}
#top .p-slider__container {
  position: relative;
}
#top #wrapper {
  padding: 0 0;
}
@media screen and (max-width: 1200px ) {
  #top #wrapper {
    padding: 60px 0 0;
  }
}
#top #wrapper .p-news {
  width: 100%;
  padding-bottom: 115px;
  background-color: #f5f5f5;
}
#top #wrapper .p-news__inner {
  width: 100%;
}
#top #wrapper .p-news__content {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 1024px) {
  #top #wrapper .p-news__content {
    flex-direction: column;
  }
}
#top #wrapper .p-news__img {
  width: 100%;
  max-width: 576px;
  flex-shrink: 0;
}
@media screen and (max-width: 1024px) {
  #top #wrapper .p-news__img {
    max-width: 100%;
    height: 300px;
  }
}
#top #wrapper .p-news__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#top #wrapper .p-news__txt {
  padding-top: 90px;
  margin-left: -80px;
}
@media screen and (max-width: 1024px) {
  #top #wrapper .p-news__txt {
    padding-top: 0;
    margin: 0;
  }
}
#top #wrapper .p-newsBox {
  background-color: #FFFFFF;
  padding: 60px 100px 80px 85px;
}
#top .p-service {
  width: 100%;
  background-color: #f5f5f5;
  padding: 115px 0;
}
#top .p-service__inner {
  width: 100%;
  max-width: 1170px;
  margin-inline: auto;
}
@media screen and (max-width: 1200px ) {
  #top .p-service__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  #top .p-service__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 568px) {
  #top .p-service__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
#top .p-service__contents {
  width: 100%;
  display: flex;
  gap: 73px;
}
@media screen and (max-width: 1200px ) {
  #top .p-service__contents {
    gap: 50px;
  }
}
@media screen and (max-width: 834px) {
  #top .p-service__contents {
    flex-direction: column-reverse;
    align-items: center;
  }
}
#top .p-service__img {
  width: 100%;
  max-width: 520px;
  flex-shrink: 0;
}
@media screen and (max-width: 1200px ) {
  #top .p-service__img {
    max-width: 480px;
  }
}
@media screen and (max-width: 1024px) {
  #top .p-service__img {
    max-width: 400px;
  }
}
@media screen and (max-width: 834px) {
  #top .p-service__img {
    max-width: 500px;
  }
}
#top .p-service__img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#top .p-service__desc {
  margin-bottom: 70px;
}
#top .p-serviceBox {
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}
#top .p-serviceBox__inner {
  padding: 45px;
}
@media screen and (max-width: 1024px) {
  #top .p-serviceBox__inner {
    padding: 40px;
  }
}
#top .p-serviceBox__head {
  font-size: 18px;
  margin-bottom: 35px;
}
#top .p-serviceBox__head > a {
  position: relative;
  display: block;
  width: 100%;
  color: #333;
}
#top .p-serviceBox__head > a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: 1px solid #004DA0;
  border-radius: 9999px;
  width: 48px;
  height: 48px;
}
#top .p-serviceBox__head > a::after {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  font-size: 17px;
  color: #004DA0;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
}
@media (any-hover: hover) {
  #top .p-serviceBox__head > a:hover::after {
    right: 13px;
  }
}
#top .p-serviceBox__item {
  padding: 25px 0 0 25px;
  border-top: 1px solid #CCCCCC;
  margin-bottom: 35px;
}
#top .p-serviceBox__item:last-child {
  margin-bottom: 0;
}
#top .p-serviceBox__item > a {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  padding-left: 27px;
  position: relative;
  transition: all 0.2s ease;
}
#top .p-serviceBox__item > a::before {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  font-size: 17px;
  color: #004DA0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 0.2s ease;
}
@media (any-hover: hover) {
  #top .p-serviceBox__item > a:hover::before {
    left: 5px;
  }
}